$b1:			#439aff;
$b2:			#156ed6;
$b3:			#00489b;
$b4:			#002a5a;
$b5:			#001833;

$gray4:			#282c34;

$gr1:			#73d073;
$gr3:			#197b19;


$white:			#dbf4ff;
$black:			#000;


$primary:		$b1;



$character-width-xl: 18.75em;
$character-width-lg: 14.75em;
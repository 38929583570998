.news-wrapper{

	@media(max-width: 767px){
		width: 80%;
	}

	@media(max-width: 575px){
		width: 90%;
	}

	width: 70%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 3rem;
	background-color: rgba(0,0,0,.7);
	box-shadow: 0 0rem 1rem #000;
	border-radius: .3rem;
	margin-top: 13rem;

	.news-header{
		display: flex;
		padding-top: 1rem;
		flex-direction: column;
		align-items: center;

		.sub-nav{
			display: flex;
		    width: 90%;
		    justify-content: space-evenly;
		    margin-top: 1em;
		}
	}

	.news-entry-wrapper{
		.news-entry{
			border-top: .01rem solid;
		    padding-top: 1rem;
		    padding-bottom: 3rem;
		}

		.icon-links {
			margin-left: 2em;
			margin-bottom: 1em;

			.tapas-logo{
				height: 48px;
			}
		}

		.news-attachments{
			padding-top: 2rem;

			.attachment{
				display: inline-flex;
    			flex-shrink: 0;
			    width: 8rem;
			    margin: .2rem;

			    .img-thumbnail {
			    	padding: 0;
			    	border: .1rem solid $primary;
			    }
			}
		}
	}
}
.header-wrapper{

	@media(max-width: 767px){
		height: 4.5rem;
	}

	box-shadow: 0 0.4rem 0.5rem $black;
    display: inline-block;
    width: 100%;
    border-bottom: .2rem solid $black;
    background-color: $white;
    position: fixed;
    top: 0;
    z-index: 99999;

    .hamburger{
    	color: $b1;
    	margin-left: 1rem;
    	margin-top: .6rem;
    	padding-left: .3rem;
	    padding-right: .3rem;
	    border: 1px solid;
	    border-radius: .3rem;
    }

    .nav-wrapper{
    	background-color: $white;
    	font-family: monospace;
    	font-size: 1.3rem;
    	font-weight: bold;

    	.nav-item{
    		cursor: pointer;
    		margin-right: 1em;
    	}
    }

	.crook-header{
		height: 6.5rem;
		background: linear-gradient(#439aff, #113152);
	    display: inline-block;
	    border-bottom: .2rem solid $black;
	    width: 100%;

	    

	    .logo-wrapper{

	    	display: flex;
	    	align-items: flex-end;

	    	.logo-text{
	    		font-family: impacto, impact;
	    		font-size: 2rem;
	    		color: $white;
	    		text-shadow: .12rem .12rem $black;
	    	}

	    	.logo{
	    		padding-left: .5rem;
	    		padding-top: .5rem;
	    		filter: drop-shadow(.12rem .24rem 0 black) drop-shadow(-.12rem 0 0 black);

		    	img{
		    		width: 3rem;
		    	}
		    }
	    }
	}
}
.crook-footer{
	margin-top: 4rem;
    padding-top: 1rem;
    background-color: $b5;
    box-shadow: 0 0rem 1rem #000;
    position: absolute;
    bottom: 0;
    width: 100%;

    .copyright{
    	font-size: .7rem;
    }

	.footer-links {

		display: flex;
		justify-content: space-evenly;

		ul{
			list-style: none;
    		padding-left: 0;
		}
	}

}
.character-wrapper-main{
	.character {
		@media(min-width: 1200px){
			width: $character-width-xl;
			height: $character-width-xl + 1em;

			img{
				width: $character-width-xl;
			}
		}

		@media(max-width: 1199px){
			width: $character-width-lg;
			height: $character-width-lg + 1em;

			img{
				width: $character-width-lg;
			}
		}

		margin: 1em;
	}
}
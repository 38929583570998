.episode-link-wrapper{
	@media(max-width: 575px){
		height: 5rem !important;
	}

	@media(max-width: 991px){
		height: 8rem;
	}

	display: flex;
    background-color: $b4;
    margin: 0 2rem;
    border-radius: .3rem;
    box-shadow: 0 0 1rem 0.4rem $black;
    padding: .5rem;
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover{
    	margin-left: .5rem;
    	margin-right: .5rem;
    	background-color: $gr3;
    	transition: margin 100ms;
    }
    .episode-deets{
    	padding-left: 1rem;
        color: $white;

    	.episode-number{
    		@media(max-width: 767px){
    			font-size: 1rem;
    		}
    	}

    	.episode-title{
    		@media(max-width: 380px){
    			max-width: 10rem;
    		}

    		@media(max-width: 575px){
    			font-size: .9rem;
    		}

    		@media(min-width: 768px){
    			padding-left: .5rem;
    		}

    		max-width: 15.5rem;
    		text-overflow: ellipsis;
    		overflow: hidden;
    		white-space: nowrap;
    	}
    }
}

.episode-icon {
    @media(max-width: 575px){
        width: 4rem !important;
    }

    width: 5rem;
    height: 5rem;
    flex-shrink: 0;

    .icon{
        padding: 0;
        background-color: rgba(0,0,0,1);
        border-radius: 0;
        border: .1rem solid $b1;
    }
}

.episode-list-wrapper{
    max-height: 450px;
    overflow-y: auto;
}

.latest-episode-wrapper{
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.read-episode{
    .read-fade-top{
        background: linear-gradient(180deg, rgb(12, 13, 16), rgba(255,0,0,0) 100%);
        height: 10em;
        position: relative;
        z-index:9999;
        top: 160px
    }

    .read-fade-bottom{
        background: linear-gradient(0deg, rgb(12, 13, 16), rgba(255,0,0,0) 100%);
        height: 10em;
        position: relative;
        z-index:9999;
        top: -160px;
    }

    .episode-container{
        max-height: 1000px;
        overflow-y: scroll;
        width: 100%;

        img{
            @media(max-width: 1300px){
                width: 99%;
            }

            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .ad-space{
            display:block;
            width: 336px;
            height: 280px;
        }
    }
}
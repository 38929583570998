.modal-content {
	.modal-header{
		background: linear-gradient(#439aff, #113152);
    	font-family: impact;
	}
}

.modal-dialog {
	&.episode-modal {
		margin-top: 0;
	    margin-bottom: 0;
	    height: 100vh;

		@media(min-width: 768px){
			max-width: 820px;
		}

		@media(max-width: 767px){
			margin: 0;
			max-width: 820px;
		}

		.modal-content {
			height: 100%;
			
			.modal-body {
				padding: 0;
				max-height: 1000px;
				overflow-y: scroll;

				.test{
					min-height: 300px;
					width: auto;

					.episode-page {
						img {
							@media(max-width: 767px){
								max-width: 100%;
							}
						}
					}
				}
				
			}
		}
	}
}
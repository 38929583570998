.sale-card-wrapper{
	width: 15rem;
	color: $black;

	.free-shipping{
		position: absolute;
	    background-color: #73d073;
	    border-radius: 1rem;
	    padding: .2rem 1rem;
	    display: inline;
	    top: 14rem;
	    left: 6rem;
	}

	.price{
		font-weight: bold;
	}
}